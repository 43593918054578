import Bowser from 'bowser';

window.isValidBrowser = function() {
  let browser = Bowser.getParser(window.navigator.userAgent);
  let isValidBrowser = browser.satisfies({
    safari: ">=15", // no version of safari lower than 15
    ie: ">=12" // no version of internet explorer
  });

  return isValidBrowser !== undefined ? isValidBrowser : true;
}
