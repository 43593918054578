
class IntersectionObserverManager {
  createObserver(dotnetObjectReference, root, rootMargin, thresholds, observedElement, callbackName) {
    let options = {
      root,
      rootMargin,
      thresholds
    };

    let handleIntersect = function(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          dotnetObjectReference.invokeMethodAsync(callbackName);
        }
      });
    }

    let observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(observedElement);

    return {
      dispose: () => observer.unobserve(observedElement)
    }
  }
}

const intersectionObserverManager = new IntersectionObserverManager();

window.intersectionObserverManager = intersectionObserverManager;

if (typeof globalThis === "object")
  globalThis.intersectionObserverManager = intersectionObserverManager;
