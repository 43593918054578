class DownloadHelper {

  constructor() {

  }

  // https://docs.microsoft.com/en-us/aspnet/core/blazor/file-downloads?view=aspnetcore-6.0
  async downloadFileFromStream(parameters) {
    const arrayBuffer = await parameters.streamRef.arrayBuffer();
    const blob = new Blob([arrayBuffer]);

    const url = URL.createObjectURL(blob);

    this.triggerFileDownload(parameters.downloadFileName, url);

    URL.revokeObjectURL(url);
  }

  triggerFileDownload(fileName, url) {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;

    if (fileName) {
      anchorElement.download = fileName;
    }

    anchorElement.click();
    anchorElement.remove();
  }
}

const downloadHelper = new DownloadHelper();

window.downloadHelper = downloadHelper;

if (typeof globalThis === "object")
  globalThis.downloadHelper = downloadHelper;
