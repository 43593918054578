/**
 * Throttle helper function.
 * @param {Function} callback
 * @param {Number} delay
 */
window.throttle = (callback, delay = 100) => {
  let inProgress = false;
  return (...args) => {
    if (inProgress) {
      return;
    }
    inProgress = true;
    setTimeout(() => {
      callback(...args);
      inProgress = false;
    }, delay);
  };
};

/**
 * Get "tabbable"/focusable elements.
 * @param {Element} element parent element
 * @returns {NodeListOf<Element>} Collection of focusable elements.
 */
window.getTabbableElements = (element) => {
    return element.querySelectorAll(
        "a[href]:not([tabindex='-1'])," +
        "area[href]:not([tabindex='-1'])," +
        "button:not([disabled]):not([tabindex='-1'])," +
        "input:not([disabled]):not([tabindex='-1']):not([type='hidden'])," +
        "select:not([disabled]):not([tabindex='-1'])," +
        "textarea:not([disabled]):not([tabindex='-1'])," +
        "iframe:not([tabindex='-1'])," +
        "details:not([tabindex='-1'])," +
        "[tabindex]:not([tabindex='-1'])," +
        "[contentEditable=true]:not([tabindex='-1']"
    );
};

/**
 * Scroll to the element top.
 * @param {Element|String} element - Element reference or element id for which scroll to top should be invoked.
 * @example
 * scrollToTop('elementId')
 * scrollToTop(elementRef)
 */
window.scrollToTop = (element) => {
  if (!element)
    return;

  const scrollOptions = { top: 0, behavior: 'smooth' };

  // If DOM Element
  if (element instanceof Element)
    element.scroll(scrollOptions);

  // If string - ID selector
  else if (typeof element === 'string' && element.length > 0)
  {
    const domElement = document.getElementById(element);
    if (domElement)
      domElement.scroll(scrollOptions);
  }
}

/**
 * Scroll element into view.
 * @param {Element|String} element - Element reference or element id for which scroll to top should be invoked.
 * @example
 * scrollIntoView('elementId')
 * scrollIntoView(elementRef)
 */
window.scrollIntoView = (element) => {
  if (!element)
    return;

  // If DOM Element
  if (element instanceof Element)
    element.scrollIntoView({ behavior: 'smooth' });

  // If string - ID selector
  else if (typeof element === 'string' && element.length > 0)
  {
    const domElement = document.getElementById(element);
    if (domElement)
      domElement.scrollIntoView({ behavior: 'smooth' });
  }
}

/**
 * Returns a DOMRect object providing information about the size of an element and its position relative to the viewport.
 * @param {Element|String} element - Element reference or element id for which getBoundingClientRect should be invoked.
 */
window.getBoundingClientRect = (element) => {
  if (!element)
    return;

  let domRect;

  // If DOM Element
  if (element instanceof Element)
    domRect = element.getBoundingClientRect();

  // If string - ID selector
  else if (typeof element === 'string' && element.length > 0)
  {
    const domElement = document.getElementById(element);
    if (domElement)
      domRect = domElement.getBoundingClientRect();
  }

  if (domRect)
    return {
      Height: Math.round(domRect.height),
      Width: Math.round(domRect.width),
      Top: Math.round(domRect.top),
      Bottom: Math.round(domRect.bottom),
      Left: Math.round(domRect.left),
      Right: Math.round(domRect.right),
      X: Math.round(domRect.x),
      Y: Math.round(domRect.y)
    };
}

window.getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

window.clearQueryParams = () => window.history.replaceState(null, '', location.pathname);

window.getUserAgentString = () => navigator.userAgent;

window.getWindowMeasurements = () => ({
  Height: Math.trunc(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight),
  Width: Math.trunc(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
});

window.addPopStateEventListener = (dotNetObjRef, eventHandlerName) =>
  window.addEventListener('popstate', async () =>
    await dotNetObjRef.invokeMethodAsync(eventHandlerName, dotNetObjRef));

// Initialize window resize event handler
window.addWindowResizeEventListener = (dotNetObjRef, eventHandlerName, throttleDelay = 100) =>
  window.addEventListener("resize",
    throttle(async () => await dotNetObjRef.invokeMethodAsync(eventHandlerName, window.getWindowMeasurements()), throttleDelay));

/**
 * Add swipe/touch event listener.
 * @param {Element} element - Element reference to which touch event listeners will be attached.
 * @param {Object} dotNetObjRef - Dotnet object reference.
 * @param {String} eventHandlerName - Event callback name.
 * @param {Number} swipeThreshold - Swipe threshold in pixels.
 */
window.addSwipeEventListener = (element, dotNetObjRef, eventHandlerName, swipeThreshold = 100) => {
  // touch start/end position
  let touchStartX = 0,
      touchEndX = 0,
      touchStartY = 0,
      touchEndY = 0;

  // Swipe direction enum equivalent
  const _swipeDirection = {
    left: 0,
    right: 1,
    down: 2,
    up: 3
  };

  // Attach touch event listeners to the document in case element reference isn't defined.
  element ??= document;

  /**
   * Invoke dotnet object ref. event callback.
   * @param {Number} swipeDirection
   * @returns {Promise<*>}
   */
  const invokeCallback = async (swipeDirection) => await dotNetObjRef.invokeMethodAsync(eventHandlerName, swipeDirection);

  /**
   * Handle touch-end event by determining swipe direction and invoking event callback.
   * @returns {Promise<void>}
   */
  const handleTouchEnd = async () => {
    // Distance
    const touchDistanceX = Math.abs(touchEndX - touchStartX),
          touchDistanceY = Math.abs(touchEndY - touchStartY);

    // Direction and validation
    const horizontalSwipe = touchDistanceX > touchDistanceY,
          validHorizontalSwipe = horizontalSwipe && touchDistanceX > swipeThreshold,
          validVerticalSwipe = !horizontalSwipe && touchDistanceY > swipeThreshold;

    // Swipe direction
    let swipeDirection = null;

    // Horizontal
    if (validHorizontalSwipe && touchEndX < touchStartX){
      swipeDirection = _swipeDirection.left;
    }
    else if (validHorizontalSwipe && touchEndX > touchStartX){
      swipeDirection = _swipeDirection.right;
    }
    // Vertical
    else if (validVerticalSwipe && touchEndY < touchStartY){
      swipeDirection = _swipeDirection.up;
    }
    else if (validVerticalSwipe && touchEndY > touchStartY){
      swipeDirection = _swipeDirection.down;
    }

    if (swipeDirection != null)
      await invokeCallback(swipeDirection);
  };

  element.addEventListener('touchstart', event => {
    const changedTouch = event.changedTouches[0];
    touchStartX = changedTouch.screenX;
    touchStartY = changedTouch.screenY;
  })

  element.addEventListener('touchend', async event => {
    const changedTouch = event.changedTouches[0];
    touchEndX = changedTouch.screenX;
    touchEndY = changedTouch.screenY;

    await handleTouchEnd();
  })
}

const windowReferences = {};

window.openWindow = (id, url) => {
    const w = 400;
    const h = 600;

    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const features =
    `
      resizable=yes,
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
    `;

  // Open the new window and return a reference to it
  const newWindow = window.open(url, id, features);
  windowReferences[id] = newWindow;
  return newWindow;
};

window.closeWindow = (id) => {
  const windowReference = windowReferences[id];
  if (windowReference) {
    windowReference.close();
    delete windowReferences[id];
  }
};

window.setIntercomVisibility = function(hidden) {
  window.Intercom('update', {
    "hide_default_launcher": hidden
  });
}
