/**
 * Will credit an affiliate signup to the referring user.
 *
 * See: https://cryptotrader.firstpromoter.com/setup_instructions?a=d2cya3so
 */
window.creditAffiliateSignUp = async (email, uid) => {

  console.info(`Attempting to credit affiliate signup for ${email}`);

  $FPROM.trackSignup({
      email: email,
      uid: uid
    },
    function() {
      console.log('FirstPromoter affiliate signup tracking completed')
    });
}
