﻿class Checkout {

  constructor() {
    this.stripe = {};
    this.elements = {};
    this.card = {};
    this.options = {};
  }

  get cardStyle() {
    return {
      base: {
        iconColor: '#505F79',
        color: '#091E42',
        fontWeight: 500,
        fontSmoothing: 'antialiased',
        fontFamily: 'Inter, sans-serif',
        fontSize: '13px',

        '::placeholder': {
          color: '#A7B2C2',
        },
      },
      invalid: {
        color: '#FF524F',
      }
    };
  }

  async initialize(options) {

    // Save options
    this.options = options;

    // Initialize stripe
    this.stripe = window.Stripe(options.stripeApiKey);

    // Create an instance of Elements.
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@500'
        },
      ],
      clientSecret: this.options.paymentIntentSecret
    });

    // Create an instance of the card Element.
    this.card = this.elements.create('card', { style: this.cardStyle });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount(`#${options.cardElementId}`);

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', function (event) {
      options.dotNetObjRef.invokeMethodAsync(options.handleCardDetailsChange, event);
    });

    // Triggered when the card Element gains focus.
    this.card.addEventListener('focus', function (event) {
      options.dotNetObjRef.invokeMethodAsync(options.handleCardDetailsFocus, event);
    });

    // Triggered when the card Element loses focus.
    this.card.addEventListener('blur', function (event) {
      options.dotNetObjRef.invokeMethodAsync(options.handleCardDetailsBlur, event);
    });
  }

  async confirmPurchase() {
    const paymentIntentSecret = this.options.paymentIntentSecret;
    const return_url = this.options.return_url;

    const {paymentIntent, error} = await this.stripe.confirmCardPayment(
      paymentIntentSecret,
      {
        payment_method: {
          card: this.card,
        },
        return_url: return_url
      },
    );

    if (paymentIntent){
      this.options.dotNetObjRef.invokeMethodAsync(this.options.handlePaymentSuccess);
    } else {
      this.options.dotNetObjRef.invokeMethodAsync(this.options.handlePaymentFailed, error.message);
    }
  }

  createToken() {

    // Convert information collected by card elements into a single-use Token
    return this.stripe.createToken(this.card).then(function ({token, error}) {
      return {
        isValid: !error,
        errorMessage: error != null ? error.message : null,
        billingTokenId: token != null ? token.id : null
      }
    });
  }

  // Cleanup checkout props. on component dispose
  dispose() {
    this.options = {};
    this.stripe = {};
    this.elements = {};
    this.card = {};
  }
}

window.Checkout = new Checkout();
