"use strict";

class PageHideInterop {
  constructor() {
    this.instance = null;
    window.addEventListener("pagehide", this.pageHideHandler);
  }

  addEvent(instance) {
    if (this.instance) {
      this.removeEvent();
    }
    this.instance = instance;
  }

  removeEvent() {
    this.instance = null;
    window.removeEventListener("pagehide", this.pageHideHandler);
  }

  pageHideHandler = (e) => {
    if (this.instance) {
      let _ = this.instance.invokeMethod("OnPageHide", e);
    }
  };
}

const pageHideInterop = new PageHideInterop();

window.PageHideInterop = pageHideInterop;

if (typeof globalThis === "object")
  globalThis.PageHideInterop = pageHideInterop;
