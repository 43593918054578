import { Interaction } from 'chart.js';

export default function interpolate(chart, e, options) {
  const items = [];

  for (const [datasetIndex, dataset] of chart.data.datasets.entries()) {
    if (!dataset.interpolate) continue;

    const meta = chart.getDatasetMeta(datasetIndex);
    if (meta.hidden) continue;

    const xScale = chart.scales[meta.xAxisID];
    const yScale = chart.scales[meta.yAxisID];

    const xValue = xScale.getValueForPixel(e.x);
    if (xValue > xScale.max || xValue < xScale.min) continue;

    const data = dataset.data;

    const index = data.findIndex((o) => o.x >= xValue);
    if (index === -1) continue;

    let interpolatedValue;

    const prev = data[index - 1];
    const next = data[index];

    if (prev && next) {
      const slope = (next.y - prev.y) / (next.x - prev.x);
      interpolatedValue = prev.y + (xValue - prev.x) * slope;
    }

    if (dataset.steppedLine && prev) {
      interpolatedValue = prev.y;
    }

    if (isNaN(interpolatedValue)) continue;

    const yPosition = yScale.getPixelForValue(interpolatedValue);
    if (isNaN(yPosition)) continue;

    const fakePoint = {
      hasValue: () => true,
      tooltipPosition: () => ({ x: e.x, y: yPosition }),
      _model: { x: e.x, y: yPosition },
      skip: false,
      stop: false,
      x: xValue,
      y: interpolatedValue,
    };

    items.push({ datasetIndex, element: fakePoint, index: 0 });
  }

  const xItems = Interaction.modes.x(chart, e, options);
  for (const item of xItems) {
    if (!chart.data.datasets[item.datasetIndex].interpolate) {
      items.push(item);
    }
  }

  return items;
}
