const initializeSelect = (
  componentReference,
  optionsElement
) => {

  const handleScrollTopReached = "HandleScrollTopReached";

  /**
   * Handle element scroll event by invoking componentInstance handler when scroll position is at the top.
   * @param {Object} event
   */
  const handleScroll = async (event) => {
    if (event.target.scrollTop === 0){
      await componentReference.invokeMethodAsync(handleScrollTopReached);
    }
  };

  /**
   * On scroll event listener.
   * @param {Object} event
   */
  optionsElement.onscroll = async (event) => handleScroll(event);

  return {
    dispose: () => optionsElement.removeEventListener("onscroll", handleScroll),
  };
}

window.initializeSelect = initializeSelect;

if (typeof globalThis === "object")
  globalThis.initializeSelect = initializeSelect;
